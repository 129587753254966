import React, { useEffect } from "react"

interface IubendaProps {
  ariaLabel?: string
  children?: any
  embed?: boolean
  href: string
  noBrand?: boolean
  title: string
}

const Iubenda: React.FunctionComponent<IubendaProps> = ({ ariaLabel, children, embed, href, noBrand, title }) => {
  useEffect(() => {
    const iubendaScriptId = "iubenda-loader"
    let script = document.getElementById(iubendaScriptId)
    if (script) {
      script.remove ? script.remove() : (script.outerHTML = "")
    }
    script = document.createElement("script")
    script.id = iubendaScriptId
    script.src = "https://cdn.iubenda.com/iubenda.js"
    document.body.appendChild(script)
  })

  return (
    <a
      aria-label={ariaLabel}
      className={`iubenda-white ${noBrand ? "no-brand" : ""} iubenda-embed ${embed ? "iub-body-embed" : "iubenda-noiframe"} is-block`
        .replace(/\s+/g, " ")
        .trim()}
      href={href}
      title={title}
    >
      {children}
    </a>
  )
}

export default Iubenda
