import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import Footer from "../components/Footer"
import Iubenda from "../components/Iubenda"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Navbar from "../components/Navbar"
import { PageProps } from "../helpers/props"

const PrivacyPolicyPage: React.FunctionComponent<PageProps> = ({ location }) => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: "Privacy Policy" })

  return (
    <Layout>
      <Meta pathname={location.pathname} title={title} />
      <Navbar />
      <section className="section">
        <div className="container">
          <h1 className="title is-1 has-text-centered">{title}</h1>
          <div className="has-text-centered">
            <Iubenda href="https://www.iubenda.com/privacy-policy/87271153" title={title}>
              {intl.formatMessage({ id: "Please click here if the policy does not load." })}
            </Iubenda>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default PrivacyPolicyPage
